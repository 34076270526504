.faq {
    background: linear-gradient(135deg, rgba(75, 0, 130, 0.1), rgba(0, 0, 102, 0.1)); /* Opacity set in RGBA */
    display: flex;
    justify-content: center;
    align-items: center;
    

    height: auto;
    min-height: 100vh;
}



.faq-container {
    padding: 0 2rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

@media only screen and (max-width: 768px) {
    .faq-container {
        padding: 8rem 2rem;
        display: flex;
        flex-direction: column;
    }

}

.faq-container-left {
    color: #000;
    font-weight: 700;
    width: 30%;

}

@media only screen and (max-width: 768px) {
    .faq-container-left {
        width: 100%;
        margin-bottom: 5rem
    }

}

.faq-container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media only screen and (max-width: 768px) {
    .faq-container-right {
        width: 100%;

    }

}

.faq-container-left h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    
}
.faq-container-left h4 {
    font-size: 4.5rem;
    margin-bottom: 1.4rem;
}

.faq-container-left p {
    font-size: 1.6rem;

}

.faq-item {
    margin-bottom: 4rem;
    font-size: 1.8rem;
}

.faq-item h4 {
    font-size: rem;
    margin-bottom: 1rem;
    font-size: 2.5rem;
}