.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    background-image: linear-gradient(rgba(245, 245, 245, 0.1), rgba(245, 245, 245, 0.1)), url('../images/contact-bg.jpg'); /* Adjust path as necessary */
    background-size: cover;
    background-position: center;
}



.contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 2rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 768px) {
    .contact-container {
        width: calc(100% - 4rem);
    }
}

.contact-header{
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.contact-header h2{
    text-align: center;
}

.contact-form{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.form-group label{
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
}

.form-group input{
    padding: 0.5rem;
    font-size: 1.6rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-group textarea{
    padding: 0.5rem;
    font-size: 1.6rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button{
    padding: 0.5rem;
    font-size: 1.6rem;
    border: none;
    border-radius: 5px;
    background-color: #4B0082;
    color: #fff;
    cursor: pointer;
    text-align: center;
    padding: 1.3rem 3rem;

}
