header {
    width: 100%;
    background: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    color: #fff;
    
    border-bottom: 0.1rem solid #333;

}

@media only screen and (max-width: 768px){
    header {
        padding: 2rem 1rem;
    }
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

header h1 {
    font-size: 5.3rem;
    width: 80%;
    text-align: center;
    margin-bottom: 1.6rem;
    line-height: 1.4;

    font-weight: 700;
    text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

header h2 {
    font-size: 2rem;
    text-align: center;
    font-weight: 400;

}

.discover-btn {
    background-color: #ff5733; /* Vibrant orange for visibility against the purple */
    color: white; /* White text for contrast */
    font-size: 18px; /* Sufficiently large for easy readability */
    padding: 12px 24px; /* Padding for a better clickable area */
    border: none; /* No border for a modern look */
    border-radius: 25px; /* Rounded corners for a friendly, approachable feel */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow to lift the button off the page */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for user interaction */
    cursor: pointer; /* Cursor indication for clickable element */
    text-transform: uppercase; /* Uppercase text for a more impactful presence */
    font-weight: bold; /* Bold font weight to make the text stand out */
    letter-spacing: 1px; /* Slight letter spacing to improve readability */
    margin-top: 5rem;
}

.discover-btn:hover, .discover-btn:focus {
    transform: translateY(-2px); /* Slight lift when hovered or focused */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover/focus for depth */
    
}

.discover-btn:active {
    transform: translateY(0); /* Reset transform on click */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Reset shadow on click */
}
