.features{
    background: linear-gradient(135deg, rgba(75, 0, 130, 0.1), rgba(0, 0, 102, 0.1)); /* Opacity set in RGBA */
    padding: 10rem 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 100vh;
}

@media only screen and (max-width: 768px) {
    .features {
        padding: 20px 0;
        height: auto;
    }

    
}

.features-container {
    max-width: 1200px; /* Maximum width of the container */
    margin: 0 auto; /* Center the container */
    text-align: center; /* Center aligns the text */
    padding: 20px;
}

.feature {
    background: #ffffff; /* White background for feature boxes */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    margin: 20px; /* Margin around each feature box */
    padding: 20px; /* Padding inside the feature boxes */
    display: inline-block; /* Align boxes side by side */
    width: calc(50% - 40px); /* Calculation to fit two boxes in one row with space */
    height: 30rem; /* Fixed height for feature boxes */
    vertical-align: top; /* Aligns boxes to the top */
    border-radius: 1.4rem; /* Rounded corners for feature boxes */
    transition: all 0.2s ease-in-out; /* Smooth transition on hover */

}

@media only screen and (max-width: 768px) {
    .feature {
        width: calc(100% - 40px); 
        height: auto;
    }
}

.feature:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.2); /* Larger shadow on hover */
}

.feature-img {
    width: 18rem;
    height: 18rem;
}

.feature h2 {
    color: #000; /* Black color for headings */
    font-size: 2rem; /* Larger font size for headings */
    margin-bottom: 1rem; /* Space between heading and text */
}




.icon-box {
    font-size: 5rem; /* Larger icons */
    margin-bottom: 1rem;
}

.features-container p {
    color: #666; /* Medium grey color for text */
    font-size: 16px; /* Standard font size */
}

.features-header h2 {
    color: #000;
    font-size: 3.6rem;
    margin-bottom: 1rem;
}

.features-header p {
    color: #666;
    font-size: 1.6rem;
    margin-bottom: 2rem;
}
