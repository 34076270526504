* {
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: 'Segoe UI', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}



html {
    box-sizing: border-box;
    font-size: 10px;
    scroll-behavior: smooth;
}

body {

    overflow-x: hidden;
}

header {
    height: 90vh;
}

section {
    height: 110vh;
    overflow: hidden;
    
}

footer {
    height: 30vh;
    background-color: rgba(255, 0, 0, 0.273);
}