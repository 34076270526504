.features-extended{
    background: #f9f9f9;

    padding: 5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: auto;
    min-height: 100vh;
    padding: 0 20rem;
    
}

@media only screen and (max-width: 768px) {
    .features-extended {
        padding: 12rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }
 
}

.features-image {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.3);
}

@media only screen and (max-width: 768px) {
    .features-image {
        width: 80%;
        margin-bottom: 5rem;
    }
 
}


.feature-ex-img {
    width: 100%;    
    height: 100%;
}

.features-ex-header {
    width: 100%;
    margin-left: 10rem;
}

.features-ex-header h2 {
    color: #000;
    font-size: 3.6rem;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 768px) {
    .features-ex-header {
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-direction: column;
    }
 
}

.features-ex-header p {
    color: #000;
    font-size: 2rem;
    margin-bottom: 1rem;
    padding: 0 0 0 0;
    text-align: justify;
    line-height: 1.4;
    
}

@media only screen and (max-width: 768px) {
    .features-ex-header p {
        padding: 0;
        margin-top: 1.5rem;
    }
 
}