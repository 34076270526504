.panel-prom-section{
    background-color: #1A2333;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    min-height: 100vh;
}

.panel-bg-image{
    position: absolute;
    top: 10rem;
    right: 10rem;
    object-fit: cover;
    object-position: center;
    scale: 0.8rem;
    opacity: 0.3;
}

.panel-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 14rem 0;
    z-index: 99;
    margin-left: 30rem;
}

.panel-image{
    scale: 0.8;
    margin-bottom: 1.5rem;

}

.panel-feature-container{
    margin-left: 15rem;
    display: flex;
    flex-direction: column; 
    margin-top: 0rem;       
}

@media only screen and (max-width: 768px) {
    .panel-bg-image {
        scale: 0.7;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.1;
    }

    .panel-image {
        scale: 0.7;
        margin-left: 0rem;
    }

    .panel-content{
        padding: 10rem 0;
        z-index: 99;
        margin-left: 0rem;
        justify-content: center;
        align-items: center;
    }

    .panel-feature-container{
        margin-left: 0rem;        
    }

    .credit-icon {
        font-size: 3.4rem;
        
    }

    .credit-header {
        font-size: 3.3rem;
        text-align: center;
    }

    .credit-feature {
        font-size: 2.5rem;
    }

        .gold-image {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

    
}