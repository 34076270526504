.footer {
    background-color: #4B0082; /* Dark purple to match the theme */
    color: white;
    padding: 2rem 10rem;
    font-family: Arial, sans-serif;
    font-size: 1.6rem;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: linear-gradient(to right, rgba(106, 17, 203, 0.8) 0%, rgba(37, 117, 252, 0.8) 100%);
}

@media only screen and (max-width: 768px) {
    .footer{
        padding: 0 0 3rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }
}

.footer-content {
    padding: 0 2rem;
}

@media only screen and (max-width: 768px) {
    .footer-content {
        margin-bottom: 4rem;
    }
}

.footer-content p {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links li {
    display: inline;
    margin-left: 2rem;
}

.footer-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #FFA500; /* A bright hover effect */
}

.footer-brand {
    margin-left: -2.5rem;
    margin-bottom: -1.5rem;
}


.footer-title {
    margin-left: -2rem;
}

@media only screen and (max-width: 768px) {
    .footer-brand{
        margin-left: -2.5rem;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
    
    }


}
