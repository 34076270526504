.navigation-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
    background-color: transparent;
    color: #fff;
    z-index: 1000;
    
    /* Enable backdrop blur */
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    box-shadow: none;
    /* Gradient background with opacity */
    /* background: linear-gradient(to right, rgba(106, 17, 203, 0.8) 0%, rgba(37, 117, 252, 0.8) 100%); */
    
}

@media only screen and (max-width: 768px) {
    .navigation-bar {
        padding: 0 2rem 0 0;
    }
}


.navigation-brand {
    display: flex;
    align-items: center;
    
}

.navigation-brand-logo{
    width: 6rem;
    height: 6rem;
    margin-right: 1rem;
}

.navigation-title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-left: -1rem;
}

@media only screen and (max-width: 768px) {
    .navigation-links {
        visibility: hidden  ;
    
    }
}

.navigation-links {
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    font-weight: 300;
    letter-spacing: 0.1rem;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    
}


.navigation-links a, a:visited, a:link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.navigation-links a:hover {
    color: #cecece;
}

.navigation-links a:not(:last-child) {
    margin-right: 2rem;
}

.navigation-button {
    display: none;
    visibility: hidden;
    font-size: 1.8rem;
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.4rem;
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    width: 11rem;
    display: flex;
    justify-content: center;
}

.menu-icon {
    cursor: pointer;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
}