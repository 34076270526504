.credit-section {
    background-color: #1A2333;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 100vh;
}

.gold-image {
    position: absolute;
    bottom:9rem;
    right: 9rem;
    object-fit: cover;
    object-position: center;
}

.credit-image {
    scale: 0.8;
    margin-left: -10rem;
}

.credit-bg-image{
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
}

.credit-content{
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 100%;
    padding: 14rem 0;
    z-index: 99;
    margin-left: -25rem;
}
.credit-feature-container{
    margin-left: 23rem;
    display: flex;
    flex-direction: column; 
    
    margin-top: 0rem;
       
}

.credit-feature{
    font-size: 3.3rem;
    color: #fff;
    margin-bottom: 0rem;
    font-weight: 400;
}

.text-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4.4rem;
    height: auto;
}

.credit-icon{
    font-size: 3.8rem;
    margin-right: 2rem;
    color: #fff;
}


.credit-header {

    font-size: 3.6rem;
    margin-bottom: 1rem;
    color: #fff;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .credit-image {
        scale: 0.7;
        margin-left: 0rem;
    }

    .credit-bg-image{
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
    }

    .credit-content{
        padding: 14rem 0 17rem 0;
        z-index: 99;
        margin-left: 0rem;
        justify-content: center;
    }

    .credit-feature-container{
        margin-left: 0rem;        
        margin-top: 0rem;
           
    }

    .credit-icon {
        font-size: 3.4rem;
        
    }

    .credit-header {
        font-size: 3.3rem;
        text-align: center;
    }

    .credit-feature {
        font-size: 2.5rem;
    }

        .gold-image {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

    
}